import moment from 'moment';

moment.locale('en', {
  week: {
    dow: 1
  }
});

const getNextRunTime = (startDate, { frequency, frequencyPeriod, frequencyDay }) => {
  const [num, freq] = frequency.split('|');
  const targetDate = moment(startDate).add(num, freq);

  if (frequencyPeriod === 'first') {
    targetDate.startOf(freq);
  } else if (frequencyPeriod === 'last') {
    targetDate.endOf(freq);
  }

  const isWeekday = targetDate.isoWeekday() > 0 && targetDate.isoWeekday() < 6;
  const isSaturday = targetDate.isoWeekday() === 6;

  // The weekday or weekend chosen should be within the same month as the target date
  if (frequencyDay === 'weekdays' && !isWeekday) {
    if (targetDate.date() / 7 < 2) {
      targetDate.add(isSaturday ? 2 : 1, 'days');
    } else {
      targetDate.subtract(isSaturday ? 1 : 2, 'days');
    }
  } else if (frequencyDay === 'weekends' && isWeekday) {
    if (targetDate.date() / 7 < 2) {
      targetDate.isoWeekday(6);
    } else {
      targetDate.isoWeekday(0);
    }
  }

  if (moment().isAfter(targetDate)) {
    return getNextRunTime(targetDate, { frequency, frequencyPeriod, frequencyDay });
  }

  return targetDate;
};

export const getNextRunTimes = (schedule, limit = 1) => {
  if (!schedule.startDate || !schedule.enabled) return [];

  if (schedule.frequency.includes('once')) {
    if (moment().isBefore(schedule.startDate)) {
      return [moment(schedule.startDate)];
    } else {
      return [];
    }
  }

  const originalStartDate = moment(schedule.startDate);
  let startDate = moment(schedule.startDate);

  const runTimes = Array.from(Array(limit).keys()).map(() => {
    const nextRunTime = getNextRunTime(startDate, schedule);

    startDate = nextRunTime.hour(originalStartDate.hour()).minute(originalStartDate.minute());

    return nextRunTime;
  });

  if (moment().isBefore(schedule.startDate)) {
    runTimes.pop();
    return [originalStartDate, ...runTimes];
  }

  return runTimes;
};
