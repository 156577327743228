var render = function () {
  var _vm$form$frequency, _vm$form$frequency2, _vm$selectedReportTem;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "label": "Name",
      "error": _vm.validationErrors.name,
      "description": "A name for your own reference"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "rounded-md bg-lighter p-2 mb-3"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.enabled,
      expression: "form.enabled"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "enabled",
      "type": "checkbox",
      "name": "enabled"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.enabled) ? _vm._i(_vm.form.enabled, null) > -1 : _vm.form.enabled
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.enabled,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "enabled", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "enabled", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "enabled", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "enabled"
    }
  }, [_vm._v("Enabled")])])])])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "reportTemplateId",
      "type": "select2",
      "config": {
        allowSearch: true
      },
      "options": _vm.reportTemplateOptions,
      "label": "Report Template",
      "description": "The report template to generate the report with",
      "error": _vm.validationErrors.reportTemplateId
    },
    on: {
      "input": _vm.onChangeReportTemplate
    },
    model: {
      value: _vm.form.reportTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportTemplateId", $$v);
      },
      expression: "form.reportTemplateId"
    }
  })], 1), _c('div', {
    staticClass: "row justify-content-between mb-3"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "reportPeriod",
      "type": "select",
      "options": _vm.reportPeriodOptions,
      "label": "Report Period",
      "description": "The period to be used by the report",
      "error": _vm.validationErrors.reportPeriod
    },
    model: {
      value: _vm.form.reportPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportPeriod", $$v);
      },
      expression: "form.reportPeriod"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "startDate",
      "label": "Scheduled Start Date",
      "type": "dateTimePicker",
      "error": _vm.validationErrors.startDate,
      "description": "First date & time the report will be generated",
      "options": {
        utc: true
      }
    },
    model: {
      value: _vm.form.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "frequency",
      "type": "select",
      "options": _vm.frequencyOptions,
      "label": "Scheduled Frequency",
      "error": _vm.validationErrors.frequency
    },
    on: {
      "input": _vm.onChangeFrequency
    },
    model: {
      value: _vm.form.frequency,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "frequency", $$v);
      },
      expression: "form.frequency"
    }
  }), !['day', 'week'].includes((_vm$form$frequency = _vm.form.frequency) === null || _vm$form$frequency === void 0 ? void 0 : _vm$form$frequency.split('|')[1]) ? _c('FormGroup', {
    attrs: {
      "id": "frequencyDay",
      "type": "radio",
      "options": _vm.frequencyDayOptions,
      "label": "Weekday/Weekends Only",
      "error": _vm.validationErrors.frequencyDay
    },
    model: {
      value: _vm.form.frequencyDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "frequencyDay", $$v);
      },
      expression: "form.frequencyDay"
    }
  }) : _vm._e(), !['day', 'week'].includes((_vm$form$frequency2 = _vm.form.frequency) === null || _vm$form$frequency2 === void 0 ? void 0 : _vm$form$frequency2.split('|')[1]) ? _c('FormGroup', {
    attrs: {
      "id": "frequencyPeriod",
      "type": "radio",
      "options": _vm.frequencyPeriodOptions,
      "label": "First/Last day of the period",
      "error": _vm.validationErrors.frequencyPeriod
    },
    model: {
      value: _vm.form.frequencyPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "frequencyPeriod", $$v);
      },
      expression: "form.frequencyPeriod"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-6 border-left"
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-between h-100"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('FormGroup', {
    attrs: {
      "id": "recipients",
      "type": "select-array",
      "label": "Recipients",
      "icon": "fa-users",
      "placeholder": "Enter email addresses",
      "error": _vm.validationErrors.recipients
    },
    model: {
      value: _vm.form.recipients,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "recipients", $$v);
      },
      expression: "form.recipients"
    }
  })], 1)])])]), _c('div', {
    staticClass: "bg-lighter rounded-md p-3 mb-3 text-center"
  }, [_vm._m(1), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.nextRunTimes, function (time, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col mt-1 font-w500"
    }, [_c('div', [_vm._v(_vm._s(time.date))]), _c('div', {
      staticClass: "text-muted small"
    }, [_vm._v(_vm._s(time.day) + " at " + _vm._s(time.time))])]);
  }), 0)]), _c('div', {
    staticClass: "rounded-md bg-lighter p-3 mb-3"
  }, [_vm._m(2), _vm._m(3), _c('FormGroup', {
    attrs: {
      "id": "subject",
      "label": "Subject",
      "error": _vm.validationErrors.subject,
      "description": "Email subject (optional)"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "message",
      "label": "Body",
      "type": "textarea",
      "placeholder": "Enter some introductory text here...",
      "error": _vm.validationErrors.message,
      "description": "Introductory text at the beginning of the email"
    },
    model: {
      value: _vm.form.message,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  })], 1), (_vm$selectedReportTem = _vm.selectedReportTemplate) !== null && _vm$selectedReportTem !== void 0 && _vm$selectedReportTem.singleSource ? _c('div', {
    staticClass: "rounded-md bg-lighter p-3 mb-3"
  }, [_vm._m(4), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" Override the account or asset this template is generated for. This is useful if you want to send a report for a specific account or asset, without creating additional templates. ")]), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" You can find the ID of an Asset or Account by using the Dynamic Report feature to generate a report for Assets/Accounts, and ensuring the ID field is included. ")]), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v("This template currently generates reports for the following:")]), _c('div', {
    staticClass: "mb-3"
  }, [_vm._l(_vm.sourceItems.filter(function (s) {
    return s.type === 'asset';
  }), function (source) {
    return _c('div', {
      key: source._id,
      staticClass: "font-w500"
    }, [_vm._v(" Site Name: " + _vm._s(source.siteName) + " ("), _c('span', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(source.type))]), _vm._v(") ")]);
  }), _vm._l(_vm.sourceItems.filter(function (s) {
    return s.type === 'account';
  }), function (source) {
    return _c('div', {
      key: source._id,
      staticClass: "font-w500"
    }, [_vm._v(" Meter No: " + _vm._s(source.meterPointNumber) + " ("), _c('span', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(source.type) + ")")])]);
  })], 2), _vm.selectedReportTemplate.source === 'asset' ? _c('FormGroup', {
    attrs: {
      "id": "assetId",
      "label": "Asset ID",
      "placeholder": "Enter an Asset ID"
    },
    model: {
      value: _vm.form.overrides.assetId,
      callback: function callback($$v) {
        _vm.$set(_vm.form.overrides, "assetId", $$v);
      },
      expression: "form.overrides.assetId"
    }
  }) : _vm._e(), _vm.selectedReportTemplate.source === 'account' ? _c('FormGroup', {
    attrs: {
      "id": "accountId",
      "label": "Account ID",
      "placeholder": "Enter an Account ID"
    },
    model: {
      value: _vm.form.overrides.accountId,
      callback: function callback($$v) {
        _vm.$set(_vm.form.overrides, "accountId", $$v);
      },
      expression: "form.overrides.accountId"
    }
  }) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" Use this form to schedule a report to be generated and sent to you or others on a regular basis. Once created, you can manage scheduled reports from the "), _c('strong', [_vm._v("Reports -> Scheduled Reports")]), _vm._v(" area. ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600 mb-3"
  }, [_c('i', {
    staticClass: "fa fa-calendar-days mr-1"
  }), _vm._v(" Schedule Preview")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "font-w600 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-envelope mr-1"
  }), _vm._v(" Customize Email")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" You can use special codes such as "), _c('strong', {
    pre: true
  }, [_vm._v("{{ reportStartDate }}")]), _vm._v(" to insert dynamic content into the Subject and Body. Click here for more details ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "font-w600 text-danger mb-2"
  }, [_c('i', {
    staticClass: "fa fa-triangle-exclamation mr-1"
  }), _vm._v(" Report Overrides")]);
}]

export { render, staticRenderFns }