<template>
  <div>
    <p class="mb-3">
      Use this form to schedule a report to be generated and sent to you or others on a regular basis. Once created, you can manage scheduled reports
      from the <strong>Reports -> Scheduled Reports</strong> area.
    </p>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-8">
        <FormGroup id="name" v-model="form.name" label="Name" :error="validationErrors.name" description="A name for your own reference" />
      </div>
      <div class="col-md-4">
        <div class="rounded-md bg-lighter p-2 mb-3">
          <div class="custom-control custom-checkbox">
            <input id="enabled" v-model="form.enabled" type="checkbox" class="custom-control-input" name="enabled" />
            <label class="custom-control-label" for="enabled">Enabled</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <FormGroup
        id="reportTemplateId"
        v-model="form.reportTemplateId"
        type="select2"
        :config="{ allowSearch: true }"
        :options="reportTemplateOptions"
        label="Report Template"
        description="The report template to generate the report with"
        :error="validationErrors.reportTemplateId"
        @input="onChangeReportTemplate"
      />
    </div>
    <div class="row justify-content-between mb-3">
      <div class="col-md-5">
        <FormGroup
          id="reportPeriod"
          v-model="form.reportPeriod"
          type="select"
          :options="reportPeriodOptions"
          label="Report Period"
          description="The period to be used by the report"
          :error="validationErrors.reportPeriod"
        />
        <FormGroup
          id="startDate"
          v-model="form.startDate"
          label="Scheduled Start Date"
          type="dateTimePicker"
          :error="validationErrors.startDate"
          description="First date & time the report will be generated"
          :options="{ utc: true }"
        />
        <FormGroup
          id="frequency"
          v-model="form.frequency"
          type="select"
          :options="frequencyOptions"
          label="Scheduled Frequency"
          :error="validationErrors.frequency"
          @input="onChangeFrequency"
        />
        <FormGroup
          v-if="!['day', 'week'].includes(form.frequency?.split('|')[1])"
          id="frequencyDay"
          v-model="form.frequencyDay"
          type="radio"
          :options="frequencyDayOptions"
          label="Weekday/Weekends Only"
          :error="validationErrors.frequencyDay"
        />
        <FormGroup
          v-if="!['day', 'week'].includes(form.frequency?.split('|')[1])"
          id="frequencyPeriod"
          v-model="form.frequencyPeriod"
          type="radio"
          :options="frequencyPeriodOptions"
          label="First/Last day of the period"
          :error="validationErrors.frequencyPeriod"
        />
      </div>
      <div class="col-md-6 border-left">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="flex-grow-1">
            <FormGroup
              id="recipients"
              v-model="form.recipients"
              type="select-array"
              label="Recipients"
              icon="fa-users"
              placeholder="Enter email addresses"
              :error="validationErrors.recipients"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-lighter rounded-md p-3 mb-3 text-center">
      <div class="font-w600 mb-3"><i class="fa fa-calendar-days mr-1"></i> Schedule Preview</div>
      <div class="row">
        <div v-for="(time, idx) in nextRunTimes" :key="idx" class="col mt-1 font-w500">
          <div>{{ time.date }}</div>
          <div class="text-muted small">{{ time.day }} at {{ time.time }}</div>
        </div>
      </div>
    </div>
    <div class="rounded-md bg-lighter p-3 mb-3">
      <p class="font-w600 mb-2"><i class="fa fa-envelope mr-1"></i> Customize Email</p>
      <p>
        You can use special codes such as <strong v-pre>{{ reportStartDate }}</strong> to insert dynamic content into the Subject and Body. Click here
        for more details
      </p>
      <FormGroup id="subject" v-model="form.subject" label="Subject" :error="validationErrors.subject" description="Email subject (optional)" />
      <FormGroup
        id="message"
        v-model="form.message"
        label="Body"
        type="textarea"
        placeholder="Enter some introductory text here..."
        :error="validationErrors.message"
        description="Introductory text at the beginning of the email"
      />
    </div>
    <div v-if="selectedReportTemplate?.singleSource" class="rounded-md bg-lighter p-3 mb-3">
      <p class="font-w600 text-danger mb-2"><i class="fa fa-triangle-exclamation mr-1"></i> Report Overrides</p>
      <p class="mb-3">
        Override the account or asset this template is generated for. This is useful if you want to send a report for a specific account or asset,
        without creating additional templates.
      </p>
      <p class="mb-3">
        You can find the ID of an Asset or Account by using the Dynamic Report feature to generate a report for Assets/Accounts, and ensuring the ID
        field is included.
      </p>
      <p class="mb-3">This template currently generates reports for the following:</p>
      <div class="mb-3">
        <div v-for="source in sourceItems.filter(s => s.type === 'asset')" :key="source._id" class="font-w500">
          Site Name: {{ source.siteName }} (<span class="text-capitalize">{{ source.type }}</span
          >)
        </div>
        <div v-for="source in sourceItems.filter(s => s.type === 'account')" :key="source._id" class="font-w500">
          Meter No: {{ source.meterPointNumber }} (<span class="text-capitalize">{{ source.type }})</span>
        </div>
      </div>
      <FormGroup
        v-if="selectedReportTemplate.source === 'asset'"
        id="assetId"
        v-model="form.overrides.assetId"
        label="Asset ID"
        placeholder="Enter an Asset ID"
      />
      <FormGroup
        v-if="selectedReportTemplate.source === 'account'"
        id="accountId"
        v-model="form.overrides.accountId"
        label="Account ID"
        placeholder="Enter an Account ID"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

moment.locale('en', {
  week: {
    dow: 1
  }
});

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

import { getNextRunTimes } from '@/lib/schedule';

export default {
  name: 'ScheduledReportForm',
  components: {
    FormGroup
  },
  extends: Form,
  data() {
    return {
      sourceOptions: [
        { label: 'Email', value: 'email' },
        { label: 'FTP', value: 'ftp' },
        { label: 'Other', value: 'other' }
      ],
      sourceItems: []
    };
  },
  computed: {
    ...mapGetters({
      validationErrors: 'report/validationErrors',
      reportTemplates: 'reportTemplate/reportTemplates',
      systemTemplates: 'reportTemplate/systemTemplates',
      users: 'company/users'
    }),
    reportTemplateOptions() {
      const templateMapping = item => ({
        label: item.name,
        value: item._id
      });

      const groups = [
        // {
        //   title: 'Reports',
        //   children: this.systemTemplates.map(templateMapping)
        // },
        {
          title: 'User Templates',
          children: this.reportTemplates.filter(item => item.access === 'user' && item.userSub === this.$auth.user.sub).map(templateMapping)
        },
        {
          title: 'Company Templates',
          children: this.reportTemplates.filter(item => item.access === 'company').map(templateMapping)
        }
      ];

      groups.forEach(group => {
        group.children.sort((a, b) => a.label.localeCompare(b.label));
      });

      return groups;
    },
    categoryOptions() {
      return [
        { label: 'Account', value: 'account' },
        { label: 'Asset', value: 'asset' },
        { label: 'Company', value: 'company' },
        ...(this.$auth.isAdmin ? [{ label: 'System', value: 'system' }] : [])
      ];
    },
    reportPeriodOptions() {
      return [
        { label: 'Previous Month', value: '1|month' },
        { label: 'Previous 2 Months', value: '2|month' },
        { label: 'Previous 3 Months', value: '3|month' },
        { label: 'Previous 6 Months', value: '6|month' },
        { label: 'Previous 1 Year', value: '1|year' },
        { label: 'Previous Week', value: '1|week' },
        { label: 'Previous 2 Weeks', value: '2|week' },
        { label: 'Previous 4 Weeks', value: '4|week' },
        { label: 'Previous Quarter', value: '1|quarter' }
      ];
    },
    frequencyOptions() {
      return [
        { label: 'One-time', value: '1|once' },
        { label: 'Daily', value: '1|day' },
        { label: 'Weekly', value: '1|week' },
        { label: 'Every 2 Weeks', value: '2|week' },
        { label: 'Every 4 Weeks', value: '4|week' },
        { label: 'Monthly', value: '1|month' },
        { label: 'Every 2 Months', value: '2|month' },
        { label: 'Quarterly', value: '3|month' },
        { label: 'Semi-annually', value: '6|month' },
        { label: 'Annually', value: '1|year' }
      ];
    },
    frequencyPeriodOptions() {
      return [
        { label: 'Normal', value: 'any' },
        { label: 'First', value: 'first' },
        { label: 'Last', value: 'last' }
      ];
    },
    frequencyDayOptions() {
      return [
        { label: 'Any', value: 'any' },
        { label: 'Weekdays', value: 'weekdays' },
        { label: 'Weekends', value: 'weekends' }
      ];
    },
    nextRunTimes() {
      return getNextRunTimes(this.form, 5).map(d => ({
        day: d.format('dddd'),
        time: d.format('HH:mm'),
        date: d.format('Do MMM YYYY')
      }));
    },
    selectedReportTemplate() {
      return this.reportTemplates.find(t => t._id === this.form.reportTemplateId);
    }
  },
  async mounted() {
    if (!this.reportTemplates.length) {
      this.listReportTemplates();
    }
    if (!this.systemTemplates.length) {
      this.listSystemTemplates();
    }

    if (this.form.reportTemplateId) {
      const sourceItems = await this.getSourceItems({ id: this.form.reportTemplateId });
      if (sourceItems?.length) this.sourceItems = sourceItems;
    }
  },
  methods: {
    ...mapActions({
      listReportTemplates: 'reportTemplate/list',
      listSystemTemplates: 'reportTemplate/listSystem',
      getSourceItems: 'reportTemplate/getSourceItems'
    }),
    onChangeFrequency() {
      this.form.frequencyDay = 'any';
      this.form.frequencyPeriod = 'any';
    },
    async onChangeReportTemplate() {
      if (this.form.reportTemplateId) {
        const sourceItems = await this.getSourceItems({ id: this.form.reportTemplateId });
        if (sourceItems?.length) this.sourceItems = sourceItems;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.border-etn-right {
  border-right: 6px solid #65c198;
}
</style>
