export const sourceOptions = [
  { label: 'Asset', value: 'asset' },
  { label: 'Account', value: 'account' },
  { label: 'Portfolio', value: 'assetGroup' }
];

export const fileFormatOptions = [
  { label: 'Excel', value: 'xlsx' },
  { label: 'CSV', value: 'csv' },
  { label: 'Chart', value: 'chart' },
  { label: 'PDF', value: 'pdf' }
];

export const frequencyOptions = [
  { label: 'Never', value: null },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly (Custom day)', value: 'weekly' },
  { label: 'Monthly (Custom date)', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Annually', value: 'annually' },
  { label: 'Monthly (Last working day)', value: 'monthly_last-working-day' },
  { label: 'Monthly (First working day)', value: 'monthly_last-day' },
  { label: 'Monthly (First Monday)', value: 'monthly_last-monday' },
  { label: 'Monthly (Last Friday)', value: 'monthly_last-friday' },
  { label: 'Quarterly (First working day)', value: 'quarterly_first-working-day' },
  { label: 'Quarterly (Last working day)', value: 'quarterly_last-working-day' },
  { label: 'PDF', value: 'pdf' }
];

export const invoiceDateFieldOptions = [
  { label: 'Invoice Date', value: 'invoiceDate' },
  { label: 'Start Date - End Date', value: 'startDateEndDate' },
  { label: 'Start Date', value: 'startDate' },
  { label: 'End Date', value: 'endDate' }
];

export const rawUnitOptions = [
  { value: false, label: 'Converted (kWh or m3)' },
  { value: true, label: 'Raw' }
];

export const consumptionSourceOptions = [
  { label: 'Combined', value: 'combined' },
  { label: 'Readings', value: 'reading' },
  { label: 'Non-cumulative', value: 'hh' },
  { label: 'Readings & Non-cumulative', value: 'reading-hh' },
  { label: 'Invoice', value: 'invoice' }
];

// Chart Options
export const chartTypeOptions = [
  { label: 'Line', value: 'line', icon: 'fa-chart-line' },
  { label: 'Area', value: 'area', icon: 'fa-chart-area' },
  { label: 'Bar', value: 'bar', icon: 'fa-chart-column' },
  { label: 'Pie', value: 'pie', icon: 'fa-chart-pie' },
  { label: 'Heatmap', value: 'heatmap', icon: 'fa-grid' }
];

const chartComparisonOptions = [
  { label: 'None', value: null },
  { label: 'Previous Period', value: 1 },
  { label: 'Previous 2 Periods', value: 2 },
  { label: 'Previous 3 Periods', value: 3 },
  { label: 'Previous 4 Periods', value: 4 },
  { label: 'Previous 5 Periods', value: 5 },
  { label: 'Previous 6 Periods', value: 6 }
];

const chartGroupByOptions = [
  { label: 'None', value: null },
  { label: 'Utility Type', value: 'type', icon: 'fa-chart-line' },
  { label: 'Meter Group', value: 'group', icon: 'fa-chart-column' },
  { label: 'Meter User (Landlord/Tenant)', value: 'meterUser', icon: 'fa-chart-column' }
];

export const chartGenerationOptions = [
  { name: 'groupBy', label: 'Group By', items: chartGroupByOptions, type: 'radio', default: null },
  { name: 'comparePeriod', label: 'Compare With', items: chartComparisonOptions, type: 'select', default: null }
];

export const chartCustomizationOptions = [
  { name: 'type', label: 'Type', items: chartTypeOptions, type: 'buttons', default: 'line' },
  {
    name: 'cumulative',
    label: 'Cumulative',
    items: [
      { label: 'No', value: false },
      { label: 'Yes', value: true }
    ],
    type: 'radio',
    default: false
  },
  {
    name: 'average',
    label: 'Average',
    items: [
      { label: 'Show', value: true },
      { label: 'Hide', value: false },
      { label: 'Only', value: 'only' }
    ],
    type: 'radio',
    default: true
  },
  {
    name: 'legend',
    label: 'Legend',
    items: [
      { label: 'Top', value: 'top' },
      { label: 'Right', value: 'right' },
      { label: 'Hide', value: false }
    ],
    type: 'radio',
    default: 'top'
  },
  {
    name: 'axisRotate',
    label: 'Axis Rotate',
    items: [
      { label: 'No', value: null },
      { label: 'Yes', value: 45 }
    ],
    type: 'radio',
    default: null
  },
  {
    name: 'axisInterval',
    label: 'Axis Interval',
    type: 'text',
    default: null,
    items: [
      { label: 'Auto', value: null },
      { label: '0', value: 0 },
      { label: '1', value: 1 },
      { label: '7', value: 7 }
    ]
  },
  {
    name: 'heatmapSlider',
    label: 'Heatmap Slider',
    items: [
      { label: 'Show', value: true },
      { label: 'Hide', value: false }
    ],
    type: 'radio',
    default: true,
    includeTypes: ['heatmap']
  },
  {
    name: 'heatmapLabels',
    label: 'Heatmap Labels',
    items: [
      { label: 'Show', value: true },
      { label: 'Hide', value: false }
    ],
    type: 'radio',
    default: false,
    includeTypes: ['heatmap']
  }
];
