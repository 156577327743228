<template>
  <SpinnerLogo v-if="loading.list" />

  <div v-else>
    <div class="row mb-4">
      <div class="col-xl-4 col-lg-4 col-md-4 col-4">
        <div class="font-w600 font-size-h5 mb-1"><i class="fad fa-screwdriver-wrench text-primary mr-2"></i>Templates</div>
        <p class="mb-3 text-muted font-w500 font-size-sm">These are reports that have been saved as a template by you and your company users.</p>
        <div class="mb-5">
          <div v-if="userTemplates.length === 0" class="alert alert-warning py-2 px-3">No Templates</div>
          <VerticalPills v-else :items="userTemplates" item-key="_id" :groups="groups.user" alt>
            <template #item="{ item }">
              <a href="#" class="nav-link" :class="{ active: selectedTemplateId === item.id }" @click.prevent="onSelect(item)">
                <div class="mb-0"><i class="fad fa-fw mr-1 text-primary" :class="`${item.icon}`"></i> {{ item.title }}</div>
              </a>
            </template>
          </VerticalPills>
        </div>

        <div>
          <div class="font-w600 font-size-h5 mb-3"><i class="fa fa-users text-primary mr-2"></i> Company Templates</div>
          <div v-if="Object.values(companyTemplates).flat().length === 0" class="alert alert-warning py-2 px-3">No Templates</div>
          <VerticalPills v-else :items="companyTemplates" item-key="_id" :groups="groups.company" alt>
            <template #item="{ item }">
              <a href="#" class="nav-link" :class="{ active: selectedTemplateId === item.id }" @click.prevent="onSelect(item)">
                <div class="mb-0"><i class="fa fa-fw mr-1 text-secondary" :class="`${item.icon}`"></i> {{ item.title }}</div>
              </a>
            </template>
          </VerticalPills>
        </div>
      </div>

      <div class="col-xl-5 col-lg-6">
        <div v-if="selectedTemplateId">
          <SectionTitle>{{ selectedTemplate.name }}</SectionTitle>
          <div class="bg-lightest p-3 rounded-md">
            <TextListItem icon="fa-list" class="mb-2" label="Category" capitalize blank>{{ selectedTemplate.category }} </TextListItem>
            <TextListItem icon="fa-user" class="mb-2" label="Created By">{{ selectedTemplate.userSub | user(users) }} </TextListItem>
            <TextListItem icon="fa-tag" class="mb-2" label="Type">{{ selectedTemplate.type | capitalize }} </TextListItem>
          </div>
          <p class="mt-3">{{ selectedTemplate.description }}</p>
          <div class="d-flex justify-content-between mt-5">
            <div>
              <button v-if="selectedTemplate.type === 'dynamic'" class="btn btn-text-primary mr-2" @click="onClickUseTemplate">
                <i class="fa fa-cogs mr-2"></i> Use Template
              </button>
              <button class="btn btn-text-secondary mr-2" @click="onClickSchedule"><i class="fa fa-calendar-clock mr-2"></i> Schedule</button>
              <button class="btn btn-text-secondary mr-2" @click="onClickDuplicate"><i class="fa fa-clone mr-2"></i> Duplicate</button>
            </div>
            <div>
              <button v-if="selectedTemplate.userSub === $auth.user.sub" class="btn btn-text-danger" @click="deleteModal = true">
                <i class="fa fa-trash mr-2"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal id="delete" :open="!!deleteModal" title="Delete Template" @close="deleteModal = false" @submit="onDeleteTemplate" />
    <Modal
      id="schedule"
      :open="modals.schedule.show"
      title="Schedule Report"
      text="Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created."
      lg-size
      :loading="modals.schedule.loading"
      @close="modals.schedule.show = false"
      @submit="onScheduleReport"
    >
      <ScheduledReportForm v-if="modals.schedule.show" v-model="modals.schedule.form" />
    </Modal>
  </div>
</template>
<script>
import { uniq } from 'lodash';
import { rawUnitOptions, formatOptions } from '../options';
import { mapActions, mapGetters } from 'vuex';

import Modal from '@/components/ConfirmModal';
import SectionTitle from '@/components/base/SectionTitle';
import TextListItem from '@/components/base/TextListItem';
import ScheduledReportForm from '@/components/forms/ScheduledReportForm';
import SpinnerLogo from '@/components/SpinnerLogo';
import VerticalPills from '@/components/base/VerticalPills';

const initialScheduleForm = () => ({
  name: '',
  startDate: new Date(),
  reportPeriod: '1|month',
  frequency: '1|month',
  frequencyDay: 'any',
  frequencyPeriod: 'any',
  subject: '',
  message: '',
  recipients: [],
  enabled: true
});

export default {
  name: 'ReportTemplates',
  components: {
    Modal,
    ScheduledReportForm,
    SectionTitle,
    SpinnerLogo,
    TextListItem,
    VerticalPills
  },
  data() {
    return {
      deleteModal: false,
      shareModal: false,
      selectedTemplateId: null,
      rawUnitOptions,
      formatOptions,
      modals: {
        schedule: {
          show: false,
          form: {},
          loading: false
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'reportTemplate/loadingAction',
      loadingScheduledReport: 'scheduledReport/loadingAction',
      templates: 'reportTemplate/reportTemplates',
      users: 'company/users'
    }),
    groups() {
      const groups = {
        company: uniq(this.templates.filter(item => item.access === 'company').map(item => item.category || 'Uncategorized')).map(g => ({
          title: g,
          show: true,
          hideTitle: !g
        })),
        user: uniq(
          this.templates.filter(item => item.access === 'user' && item.userSub === this.$auth.user.sub).map(item => item.category || 'Uncategorized')
        ).map(g => ({ title: g, show: true, hideTitle: !g }))
      };

      groups.company.sort((a, b) => a.title.localeCompare(b.title));
      groups.user.sort((a, b) => a.title.localeCompare(b.title));

      return groups;
    },
    companyTemplates() {
      const templates = [...this.templates].filter(item => item.access === 'company');

      templates.sort((a, b) => a.name.localeCompare(b.name));

      return templates.reduce((acc, item) => {
        const category = item.category || '';

        if (!acc[category]) acc[category] = [];

        acc[category].push({
          id: item._id,
          title: item.name,
          access: item.access,
          icon: 'fa-earth-europe'
        });

        return acc;
      }, {});
    },
    userTemplates() {
      console.log(this.$auth.user.sub);
      const templates = [...this.templates].filter(item => item.access === 'user' && item.userSub === this.$auth.user.sub);

      templates.sort((a, b) => a.name.localeCompare(b.name));

      return templates.reduce((acc, item) => {
        const category = item.category || 'Uncategorized';

        if (!acc[category]) acc[category] = [];

        acc[category].push({
          id: item._id,
          title: item.name,
          access: item.access,
          icon: 'fa-file-invoice'
        });

        return acc;
      }, {});
    },
    selectedTemplate() {
      return this.templates.find(item => item._id === this.selectedTemplateId);
    }
  },
  async mounted() {
    this.listTemplates();
    this.listUsers({ id: this.$auth.companyId });
  },
  methods: {
    ...mapActions({
      listTemplates: 'reportTemplate/list',
      deleteTemplate: 'reportTemplate/remove',
      updateTemplate: 'reportTemplate/update',
      createTemplate: 'reportTemplate/create',
      listUsers: 'company/listUsers',
      createScheduledReport: 'scheduledReport/create'
    }),
    onSelect(item) {
      this.selectedTemplateId = item.id;
    },
    async onDeleteTemplate() {
      try {
        await this.deleteTemplate({
          id: this.selectedTemplate._id
        });

        this.$toasted.success('Template deleted successfully');
        this.listTemplates();

        this.deleteModal = false;
      } catch (e) {
        this.$toasted.error('Something went wrong');
      }

      this.selectedTemplateId = null;
    },
    onClickUseTemplate() {
      this.$router.push({
        name: 'reports-dynamic',
        query: {
          template: this.selectedTemplate._id
        }
      });
    },
    onClickGenerate() {
      this.$router.push({
        name: 'reports-generate',
        query: {
          template: this.selectedTemplate._id
        }
      });
    },
    onClickSchedule() {
      this.modals.schedule.form = {
        ...initialScheduleForm(),
        selectedTemplateId: this.selectedTemplateId
      };

      this.modals.schedule.show = true;
    },
    async onClickDuplicate() {
      // Check name doesn't already exist
      const existingNames = this.templates.map(t => t.name);

      const findName = (name, i = 1) => {
        const fullName = `${name} (${i})`;

        if (existingNames.includes(fullName)) {
          return findName(name, i + 1);
        }

        return fullName;
      };

      await this.createTemplate({
        data: {
          ...this.selectedTemplate,
          _id: undefined,
          name: findName(this.selectedTemplate.name)
        }
      });

      this.listTemplates();
    },
    async onScheduleReport() {
      this.modals.schedule.loading = true;

      await this.createScheduledReport({
        data: this.modals.schedule.form
      });

      this.modals.schedule = {
        show: false,
        form: {},
        loading: false
      };
    }
  }
};
</script>
